/* Footer.module.css */

.footer {
  background-color: rgb(0, 0, 0);
  color: white;
  padding: 20px 40px; /* Adjust padding as needed */
  display: flex;
  flex-direction: row; /* Ensure the main axis is horizontal */
  align-items: center; /* Vertically center items */
  justify-content: space-between; /* Space between the content and logo */
}

.updateSection,
.contactInfo {
  margin: 10px 0;
}

.footerHeading {
  font-weight: bold;
  letter-spacing: 0px;
  font-size: 2em;
}

.customHr {
  border: none;
  height: 1px;
  background-color: white;
  width: 90%;
  margin: 20px 0;
}

.linkWhite,
.contactInfo a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.linkWhite:hover,
.linkWhite:focus {
  text-decoration: underline;
}

.contactSection {
  /* This section can remain empty if no specific styles are needed */
}

.logoContainer {
  /* No changes needed here for large screens */
}

.contactImage {
  max-width: 300px; /* Set the maximum width for large screens */
  height: auto;
}

/* Responsive styles */
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    text-align: center;
  }

  .customHr {
    width: 100%;
  }

  .logoContainer {
    margin-top: 20px; /* Add margin at the top for mobile */
    order: -1; /* Make sure the logo is above the text content */
  }

  .contactImage {
    width: 250px; /* Slightly larger logo for mobile, adjust as needed */
    height: auto;
  }
}
