/* Style the accordion section */

/* Style the buttons that are used to open and close the accordion panel */

.accordion-title {
  text-align: left;
  font-weight: 600;
  font-size: 14px;
}

.accordion-content-text {
  font-weight: 400;
  font-size: 14px;
  padding: 0px 15px;
}
