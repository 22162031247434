.column {
  float: left;
  width: 50%;
}

@media (max-width: 600px) {
  .column {
    width: 100%;
  }
}

.winner {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
.textLoopContainer {
  white-space: nowrap;
  overflow: hidden;
  animation: slide 10s linear infinite;
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.playoffBox {
  background-color: #f0f0f0; /* Light grey background */
  color: #333; /* Dark text color for contrast */
  text-align: center; /* Center the text horizontally */
  padding: 20px; /* Add some padding inside the box */
  width: 300px; /* Set the width of the box */
  margin: 0 auto; /* Center the box horizontally on the page */
  border: 1px solid #ccc; /* Optional: add a border */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add a subtle shadow */
  border-radius: 5px; /* Optional: round the corners */
  font-weight: bold;
}

.finalsBox {
  background-color: #5f5f5f; /* Light grey background */
  color: #cfcfcf; /* Dark text color for contrast */
  text-align: center; /* Center the text horizontally */
  padding: 20px; /* Add some padding inside the box */
  width: 300px; /* Set the width of the box */
  margin: 0 auto; /* Center the box horizontally on the page */
  border: 1px solid #5f5f5f; /* Optional: add a border */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add a subtle shadow */
  border-radius: 5px; /* Optional: round the corners */
  font-weight: bold;
  margin: 10px;
}

.champsBox {
  background-color: #f0f0f0; /* Light grey background */
  color: #333; /* Dark text color for contrast */
  text-align: center; /* Center the text horizontally */
  padding: 20px; /* Add some padding inside the box */
  margin: 0 auto; /* Center the box horizontally on the page */

  border-radius: 5px; /* Optional: round the corners */
  font-weight: bold;
}
