.custom-dropdown-menu {
  position: absolute;
  margin-top: 8px;
  right: 1%;
  list-style: none;
  text-align: start;
}

.custom-dropdown-menu li {
  background: rgb(0, 128, 90);
  color: pink;
  width: 100%;
  cursor: pointer;
}

.custom-dropdown-menu a {
  color: pink;
  width: 100%;
  cursor: pointer;
}

.custom-dropdown-menu a:hover {
  color: yellow;
}

.custom-dropdown-menu li:hover {
  background: rgb(255, 0, 221);
}

.custom-dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
}

.custom-dropdown-menu-new {
  position: absolute;
  /* margin-top: 800px; */
  /* right: 1%; */
  list-style: none;
  text-align: start;
}

.custom-dropdown-menu-new li {
  background: rgb(0, 128, 90);
  color: pink;
  width: 100%;
  cursor: pointer;
}

.custom-dropdown-menu-new a {
  color: pink;
  width: 100%;
  cursor: pointer;
}

.custom-dropdown-menu-new a:hover {
  color: yellow;
}

.custom-dropdown-menu-new li:hover {
  background: rgb(255, 0, 221);
}

.custom-dropdown-menu-new.clicked {
  display: none;
}

.new-dropdown-item2 {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content-block {
  display: block;
}
