:root {
  --background-color: rgb(0, 0, 0);
  --button-color: white;
  --nav-item-color: #c0c0c0;
  --custom-text-color: white;
}

body {
  margin: 0;
}

.custom-nav-item {
  /* display: flex; */
  /* align-items: center; */
  /* margin-top: 100px; */
}

.custom-nav-links {
  color: var(--custom-text-color);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border: 1px solid transparent;
}

.custom-nav-links:hover {
  border: white 1px solid;
  border-radius: 4px;
  color: white;
  transition: all 0.2s ease-out;
  padding: 0.5rem 1rem;
}

.custom-nav-dropdown-item {
  color: white;
  cursor: pointer;
  padding: 0.5rem 1rem;
}

.custom-nav-button {
  color: var(--custom-text-color);
  text-decoration: none;
  /* padding: 0.5rem 1rem; */
  border-radius: 6px;
}

.custom-nav-button:hover {
  /* background: var(--button-color); */
  color: white;
  transition: 250ms;
  /* padding: 0.5rem 1rem; */
}

.nav-active {
  background-color: grey;
  border-radius: 6px;
  font-weight: bold;
  color: black;
}

.dropdown-hover {
  /* background-color: var(--button-color); */
  color: white;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: var(--custom-text-color);
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (min-width: 1160px) {
  .custom-nav-menu-desktop {
    display: flex;
    height: 100%;
    list-style: none;
    align-items: center;
  }

  .nav-desktop-menu {
    display: none;
  }

  .accordion-section {
    display: none;
    /* flex-direction: column; */
    background-color: tomato;
  }
}

@media screen and (max-width: 1160px) {
  .nav-desktop-menu {
    display: flex;
    height: 100%;
    align-items: center;
    /* margin-top: 15px; */
    /* margin-right: 2%; */
    /* margin: 0px; */
  }

  .custom-nav-menu-desktop {
    display: none;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: var(--button-color);
    border: white 4px solid;
  }

  .menu-icon {
    display: block;
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: var(--custom-text-color);
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    width: 100%;
    text-decoration: none;
    color: white;
    font-size: 15px;
    border-radius: 20px;
    padding: 15px;
    margin: 0px;
  }

  .nav-links-mobile:hover {
    background: var(--nav-item-color);
    color: black;
    text-decoration: none;
    transition: 250ms;
    border-radius: 20px;
  }

  .accordion-section {
    display: flex;
    /* flex-direction: column; */
    background-color: rgb(49, 90, 163);
  }
}

.accordion-content {
  /* overflow: auto; */
  overflow-y: hidden;
  width: 100%;
  transition: max-height 0.6s ease;
}

.accordion-nav-button {
  color: var(--custom-text-color);
  text-decoration: none;
  padding: 0.5rem 1rem;
  text-align: left;
  border-radius: 4px;
  width: 100%;
  text-decoration: none;
  color: black;
  font-size: 15px;
  border-radius: 20px;
  padding: 15px;
  margin: 0.5rem 0;
}

.accordion-nav-button:hover {
  background: var(--button-color);
  color: black;
  transition: 250ms;
}

.custom-accordion-button {
  /* background-color: transparent; */
  color: #444;
  cursor: pointer;
  border: 4px;
  /* outline: none; */
  background-color: green;
  transition: background-color 0.25s ease;
  height: 100%;
}
